import { ref, Ref } from 'vue';

import { CompanyApi } from '@/api';
import { CompanyCmSponsor } from '@/api/openapi';

import useLoading from '@/composables/loading';
import { httpCode } from '@/common/constant';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';

interface CompanyCmSponsorData {
  list: CompanyCmSponsor[];
  total: number;
}

interface Companies {
  isLoading: Ref<boolean>;
  origCompanies: Ref<CompanyCmSponsor[]>;
  companies: Ref<CompanyCmSponsor[]>;
  reload: () => Promise<void>;
  filterCompanies: (modeOption: string, searchText: string) => void;
  clearFilter: () => void;
  deleteCompany: (id: number) => void;
}

const options = {
  equals: '等しい',
  notEquals: '等しくない',
  contains: '含む',
  notContains: '含まない'
};

export const optionsList = Object.keys(options).map((key, i) => ({
  id: i.toString(),
  key: key,
  label: options[key]
}));

export const useCompanies = (props: { companyId: number }): Companies => {
  const origCompanies = ref([] as CompanyCmSponsor[]);
  const companies = ref([] as CompanyCmSponsor[]);
  const [isLoading, fetch] = useLoading(_fetch);

  const reload = async () => {
    const companiesData = await fetch(props);
    // nameの昇順でソート
    companiesData.list.sort((a, b) =>
      a.cmSponsorName < b.cmSponsorName ? -1 : 1
    );
    // 自社を先頭に移動（isOther=falseが自社）
    const ownCompany = companiesData.list.find(
      company => company.isOther === false
    );
    if (ownCompany) {
      companiesData.list.splice(companiesData.list.indexOf(ownCompany), 1);
      companiesData.list.unshift(ownCompany);
    }
    origCompanies.value = companiesData.list;
    companies.value = companiesData.list;
  };

  const filterCompanies = (modeOption: string, searchText: string) => {
    companies.value = origCompanies.value.filter(company => {
      switch (modeOption) {
        case options.equals:
          return company.cmSponsorName === searchText;
        case options.notEquals:
          return company.cmSponsorName !== searchText;
        case options.contains:
          return company.cmSponsorName.includes(searchText);
        case options.notContains:
          return !company.cmSponsorName.includes(searchText);
      }
    });
  };

  const clearFilter = () => {
    companies.value = origCompanies.value;
  };

  const deleteCompany = async (cmSponsorId: number) => {
    const index = companies.value.findIndex(
      company => company.cmSponsorId === cmSponsorId
    );
    if (index >= 0) {
      companies.value.splice(index, 1);

      try {
        await CompanyApi.deleteCompaniesCompanyIdProductCmSponsorsCmSponsorId(
          props.companyId,
          cmSponsorId
        );
      } catch (e) {
        handleError(e);
      }
    }
  };

  (async () => {
    reload();
  })();

  return {
    isLoading,
    origCompanies,
    companies,
    reload,
    filterCompanies,
    clearFilter,
    deleteCompany
  };
};

const _fetch = async (props): Promise<CompanyCmSponsorData> => {
  try {
    const res = await CompanyApi.getCompaniesCompanyIdCmSponsors(
      props.companyId
    );
    return {
      total: res.data.length,
      list: res.data
    } as CompanyCmSponsorData;
  } catch (e) {
    if (e.status === httpCode.forbidden) {
      toast({
        title: '企業を検索する権限がありません',
        message: e.message,
        variant: 'error'
      });
    } else if (e.status === httpCode.timeout) {
      toast({
        title: '企業を検索がタイムアウトしました',
        message: e.message,
        variant: 'error'
      });
    } else {
      handleError(e);
    }
    return {} as CompanyCmSponsorData;
  }
};
