import { ref, Ref } from 'vue';

import { CompanyApi } from '@/api';
import { CompanyCmSponsor } from '@/api/openapi';

import useLoading from '@/composables/loading';
import { httpCode } from '@/common/constant';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';

interface Brand {
  isLoading: Ref<boolean>;
  selectedCompanyId: Ref<number | null>;
  isOther: Ref<boolean>;
  updateCompany: (companyId: number | null, isOther: boolean) => void;
}

export const useBrand = (props: {
  companyId: number;
  cmSponsorId: number | null;
}): Brand => {
  const selectedCompanyId = ref(null) as Ref<number | null>;
  const isOther = ref(false);
  const [isLoading, fetch] = useLoading(_fetch);

  (async () => {
    const res = await fetch(props);
    if (res) {
      const ownCompany = res.find(company => company.isOther === false);
      if (props.cmSponsorId) {
        selectedCompanyId.value = props.cmSponsorId;
        isOther.value = props.cmSponsorId !== ownCompany?.cmSponsorId;
      } else {
        selectedCompanyId.value = ownCompany?.cmSponsorId || null;
        isOther.value = ownCompany?.isOther || false;
      }
    }
  })();

  const updateCompany = (newCompanyId: number | null, newIsOther: boolean) => {
    selectedCompanyId.value = newCompanyId;
    isOther.value = newIsOther;
  };

  return {
    isLoading,
    selectedCompanyId,
    isOther,
    updateCompany
  };
};

const _fetch = async (props): Promise<CompanyCmSponsor[]> => {
  try {
    const res = await CompanyApi.getCompaniesCompanyIdCmSponsors(
      props.companyId
    );
    return res.data;
  } catch (e) {
    handleError(e);
    if (e.status === httpCode.forbidden) {
      toast({
        title: '企業を検索する権限がありません',
        message: e.message,
        variant: 'error'
      });
    }
    if (e.status === httpCode.timeout) {
      toast({
        title: '企業を検索がタイムアウトしました',
        message: e.message,
        variant: 'error'
      });
    }
    return [] as CompanyCmSponsor[];
  }
};
