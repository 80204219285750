import { AreaInfoIdEnum } from '@/api/openapi';
import { subDays } from 'date-fns';
import { ref, Ref, computed, ComputedRef } from 'vue';
import { getAvailableDate, getAvailableEndDate } from '@/common/dates';

interface Duration {
  start: Ref<Date>;
  end: Ref<Date>;
  minDate: Ref<Date>;
  maxDate: Ref<Date>;
  durationValid: ComputedRef<string>;
}

interface Filter {
  label: string;
  id: string;
}

interface Config extends Duration {
  filterTypes: Filter[];
  filterTypeValue: Ref<string[]>;
  initFilter: () => void;
}

const useDuration = (areaValue: Ref<AreaInfoIdEnum | undefined>): Duration => {
  const minDate = ref(getAvailableDate(areaValue.value as AreaInfoIdEnum));
  const maxDate = ref(getAvailableEndDate(areaValue.value as AreaInfoIdEnum));
  // startDateは直近90日間
  const start = ref(subDays(maxDate.value, 90));
  const end = ref(maxDate.value);
  const durationValid = computed(() => {
    if (start.value > end.value) {
      return '開始日は終了日より前を設定してください';
    }
    return '';
  });

  return {
    start,
    end,
    minDate,
    maxDate,
    durationValid
  };
};

const FILTER_TYPE: Filter[] = [
  { label: '利用中', id: 'used' },
  { label: '未使用', id: 'unused' }
];

export const useConfig = (
  areaValue: Ref<AreaInfoIdEnum | undefined>
): Config => {
  const duration = useDuration(areaValue);
  const filterTypeValue = ref(['used', 'unused']);
  const initFilter = () => {
    filterTypeValue.value = ['used', 'unused'];
  };

  return {
    ...duration,
    filterTypes: FILTER_TYPE,
    filterTypeValue,
    initFilter
  };
};
