import { Ref, ref } from 'vue';
import { useRoute } from 'vue-router';

import { CmSponsorApi, CompanyApi } from '@/api';
import {
  CmSponsorList,
  CmSponsor,
  CompanyCmSponsor,
  AnalysisUsedCompany
} from '@/api/openapi';

import useLoading from '@/composables/loading';

interface SearchCompany {
  companies: Ref<CmSponsor[]>;
  usedCompanies: Ref<AnalysisUsedCompany[]>;
  isLoading: Ref<boolean>;
  isLoadingUsed: Ref<boolean>;
  searchCmCompany: (v: string) => Promise<void>;
  searchUsedCompany: () => Promise<void>;
  resetSearch: () => void;
}

export const useSearchCompanies = (props: {
  currentCompanies: CompanyCmSponsor[];
}): SearchCompany => {
  const companyId = useRoute().params.companyId.toString();
  const companies = ref([] as CmSponsor[]);
  const usedCompanies = ref([] as AnalysisUsedCompany[]);
  const [isLoading, fetch] = useLoading(_fetch);
  const [isLoadingUsed, fetchUsed] = useLoading(_fetchUsed);

  const searchCmCompany = async (v: string) => {
    const { list } = await fetch(v);
    companies.value = list.filter(
      v => !props.currentCompanies.some(c => c.cmSponsorId === v.id)
    );
  };

  const searchUsedCompany = async () => {
    const list = await fetchUsed({
      companyId
    });
    usedCompanies.value = filterUsedCompanies({
      companies: list,
      addedCompanies: props.currentCompanies
    });
  };

  const resetSearch = () => {
    companies.value = [] as CmSponsor[];
    usedCompanies.value = [] as AnalysisUsedCompany[];
  };

  (async () => {
    // await searchUsedCompany();
  })();

  return {
    companies,
    usedCompanies,
    isLoading,
    isLoadingUsed,
    searchCmCompany,
    searchUsedCompany,
    resetSearch
  };
};

const filterUsedCompanies = (props: {
  companies: AnalysisUsedCompany[];
  addedCompanies: CompanyCmSponsor[];
}) => {
  // 追加済みの企業を除外
  const usedIds = props.addedCompanies.map(company => company.cmSponsorId);
  return props.companies.filter(
    company => !usedIds.includes(company.affiliatedCompanyId)
  );
};

export const _fetchUsed = async (props: {
  companyId: string;
}): Promise<AnalysisUsedCompany[]> => {
  try {
    // 分析で利用中の企業を取得する
    const res = await CompanyApi.getCompaniesCompanyIdAnalysisUsedCompanies(
      parseInt(props.companyId)
    );
    return res.data;
  } catch (e) {
    console.error(e);
    return [] as AnalysisUsedCompany[];
  }
};

export const _fetch = async (cmSponsorName: string): Promise<CmSponsorList> => {
  try {
    const { data } = await CmSponsorApi.getCmSponsorsSearch(
      undefined,
      undefined,
      cmSponsorName || undefined
    );
    return data;
  } catch (e) {
    console.error(e);
    return {} as CmSponsorList;
  }
};
