import { Ref, ref } from 'vue';
import { useRoute } from 'vue-router';

import { CompanyApi } from '@/api';
import { CmSponsor, CompanyToProductCmSponsor } from '@/api/openapi';

import useLoading from '@/composables/loading';
// import { httpCode } from '@/common/constant';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';
import Axios from 'axios';

interface CreateCompany {
  company: Ref<CmSponsor>;
  isPosting: Ref<boolean>;
  createCompany: () => Promise<CompanyToProductCmSponsor>;
}

export const useCreateCompany = (): CreateCompany => {
  const company = ref({} as CmSponsor);
  const route = useRoute();
  const companyId = Number(route.params.companyId);
  const [isPosting, post] = useLoading(_post);

  const createCompany = async () => {
    return await post({ companyId, company: company.value });
  };

  return {
    company,
    isPosting,
    createCompany
  };
};

const _post = async (props: {
  companyId: number;
  company: CmSponsor;
}): Promise<CompanyToProductCmSponsor> => {
  const { companyId, company } = props;

  try {
    const {
      data
    } = await CompanyApi.postCompaniesCompanyIdProductCmSponsorsCmSponsorId(
      companyId,
      company.id
    );
    toast({
      title: '成功',
      message: `企業を追加しました`,
      variant: 'success'
    });
    return data;
  } catch (e) {
    if (Axios.isAxiosError(e)) {
      handleError(e, '企業追加失敗');
      return {} as CompanyToProductCmSponsor;
    } else {
      toast({
        title: '失敗しました',
        message: `企業追加に失敗しました`,
        variant: 'error'
      });
      return {} as CompanyToProductCmSponsor;
    }
  }
};
