import { AreaInfoIdEnum, SelectableAreasList } from '@/api/openapi';
import { useAreaOptions } from '@/store/areaOptions';
import { storeToRefs } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';
import { useRoute } from 'vue-router';

// エリア
export interface Area {
  areaValid: ComputedRef<string>;
  areaValue: Ref<AreaInfoIdEnum | undefined>;
  areaSelectOptions: Ref<SelectableAreasList[]>;
  isLoadingAreaStore: Ref<boolean>;
  areaOptionStore: ReturnType<typeof useAreaOptions>;
  getArea: () => Promise<void>;
}

export const useArea = (): Area => {
  const areaOptionStore = useAreaOptions();
  const route = useRoute();

  const {
    isLoading: isLoadingAreaStore,
    selectOptions: areaSelectOptions
  } = storeToRefs(areaOptionStore);

  const areaValue = ref();

  const areaValid = computed(() => {
    if (!areaValue.value) {
      return '選択してください';
    }
    return '';
  });

  const getArea = async () => {
    const data = await areaOptionStore.fetch(route);
    if (data) {
      areaValue.value = data;
    }
  };

  return {
    areaValid,
    areaValue,
    areaSelectOptions,
    isLoadingAreaStore,
    areaOptionStore,
    getArea
  };
};
