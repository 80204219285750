import { ref, Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { format, subDays } from 'date-fns';
import { CompanyApi } from '@/api';
import { CompanyCmSponsorProduct, AreaInfoIdEnum } from '@/api/openapi';

import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { ColumnFilterMatchModeOptions } from 'primevue/column';
import { SETTINGS_ROUTES } from '@/router';
import { httpCode } from '@/common/constant';
import { handleError } from '@/common/handleError';
import { DATE_FORMAT } from '@/common/format';
import useLoading from '@/composables/loading';
import { useConfig } from '@/composables/brand/config';
import { toast } from '@/components/ui/Toast';

// Omitユーティリティ型を使ってCompanyCmSponsorProductからlastCmBroadcastDateを除外
type ProductBase = Omit<CompanyCmSponsorProduct, 'lastCmBroadcastDate'>;

// APIからstringでくるため、sort用にDateに変換
export interface Product extends ProductBase {
  lastCmBroadcastDate: Date;
}

interface ProductData {
  list: Product[];
  total: number;
}

// APIからのデータを受け取り、Productの形式に変換する関数
const convertToProduct = (data: CompanyCmSponsorProduct): Product => {
  return {
    ...data,
    lastCmBroadcastDate: new Date(data.lastCmBroadcastDate)
  };
};

interface Products {
  isLoading: Ref<boolean>;
  startDate: Ref<string>;
  endDate: Ref<string>;
  origProducts: Ref<Product[]>;
  products: Ref<Product[]>;
  reload: (sponsorId: number | null, isOther: boolean) => Promise<void>;
  goToEdit: (
    id: number,
    isTvalProduct: boolean,
    affiliatedCompanyId: number | null
  ) => Promise<void>;
}

interface DataTabelFilter {
  id: string;
  operator: string;
  constraints: { value: null; matchMode: string }[];
}

interface DataTableReturnType {
  filters: Ref<{ [key: string]: DataTabelFilter }>;
  initFilter: () => void;
}

const STR_FILTER: ColumnFilterMatchModeOptions[] = [
  { label: '等しい', value: FilterMatchMode.EQUALS },
  { label: '等しくない', value: FilterMatchMode.NOT_EQUALS },
  { label: '含む', value: FilterMatchMode.CONTAINS },
  { label: '含まない', value: FilterMatchMode.NOT_CONTAINS }
];

export const HEADERS: {
  id: string;
  label: string;
  class: string;
  sortable?: boolean;
  sortField?: string;
  filter?: boolean;
  matchMode?: string;
  filterOptions?: ColumnFilterMatchModeOptions[];
  filterField?: string;
}[] = [
  {
    id: 'name',
    label: '商品／ブランド名',
    class: 'p-sortable-column--name',
    sortable: true,
    filter: true,
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER,
    filterField: 'string'
  },
  {
    id: 'group',
    label: '名寄せ',
    class: 'p-sortable-column--sm center'
  },
  {
    id: 'grp',
    label: '個人GRP',
    class: 'p-sortable-column--sm end',
    sortable: true
  },
  {
    id: 'lastCmBroadcastDate',
    label: '直近CM放送日',
    class: 'p-sortable-column--md center lastCmBroadcastDate',
    sortable: true
  },
  {
    id: 'actions',
    label: '',
    class: 'p-sortable-column--sh center last-colum'
  }
];

export const useProducts = (
  companyId: number,
  areaValue: Ref<AreaInfoIdEnum | undefined>
): Products => {
  const { maxDate } = useConfig(areaValue);
  // startDateは直近90日間
  const startDate = ref(format(subDays(maxDate.value, 90), DATE_FORMAT));
  const endDate = ref(format(maxDate.value, DATE_FORMAT));
  const products = ref([] as Product[]);
  const origProducts = ref([] as Product[]);
  const [isLoading, fetch] = useLoading(_fetch);
  const router = useRouter();
  const route = useRoute();

  const reload = async (cmSponsorId: number | null, isOther: boolean) => {
    if (cmSponsorId) {
      const productData = await fetch({
        companyId: companyId,
        cmSponsorId: cmSponsorId,
        startDate: startDate.value,
        endDate: endDate.value,
        isOther: isOther
      });
      origProducts.value = productData.list;
      products.value = productData.list;
    } else {
      origProducts.value = [];
      products.value = [];
    }
  };

  const goToEdit = async (
    id: number,
    isTvalProduct: boolean,
    affiliatedCompanyId: number | null
  ) => {
    await router.push({
      name: SETTINGS_ROUTES.brandEdit,
      params: { ...route.params, brandId: id, companyId: companyId },
      query: {
        start: startDate.value,
        end: endDate.value,
        isTvalProduct: isTvalProduct ? 'true' : 'false',
        affiliatedCompanyId: affiliatedCompanyId
      }
    });
  };

  return {
    isLoading,
    startDate,
    endDate,
    origProducts,
    products,
    reload,
    goToEdit
  };
};

export const useDataTable = (): DataTableReturnType => {
  const filters = ref();
  const initFilter = () => {
    const tmp = HEADERS.map(v => {
      if (!v.filter) return;
      return {
        id: v.id,
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: v.matchMode }]
      };
    }).filter(Boolean) as DataTabelFilter[];
    filters.value = tmp.reduce((p, c) => {
      return { ...p, [c.id]: c };
    }, {});
  };
  initFilter();

  return {
    filters,
    initFilter
  };
};

const _fetch = async (props: {
  companyId: number;
  cmSponsorId: number;
  startDate: string;
  endDate: string;
  isOther: boolean;
}): Promise<ProductData> => {
  try {
    const res = await CompanyApi.getCompaniesCompanyIdCmSponsorsCmSponsorIdProducts(
      props.companyId,
      props.cmSponsorId,
      props.startDate,
      props.endDate,
      props.isOther
    );
    const data = res.data.map(v => convertToProduct(v));
    return {
      list: data,
      total: data.length
    } as ProductData;
  } catch (e) {
    if (e.status === httpCode.forbidden) {
      toast({
        title: '商品を検索する権限がありません',
        message: e.message,
        variant: 'error'
      });
    } else if (e.status === httpCode.timeout) {
      toast({
        title: '商品を検索がタイムアウトしました',
        message: e.message,
        variant: 'error'
      });
    } else {
      handleError(e);
    }
    return {} as ProductData;
  }
};
